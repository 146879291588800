import React, { useEffect, useState } from 'react';

import styles from './Clock.module.css';

const Clock = () => {
  const [hours, setHours] = useState('00');
  const [mins, setMins] = useState('00');
  const [secs, setSecs] = useState('00');
  const [offset, setOfset] = useState('0');

  useEffect(()=> {
    const savedTimeZone = localStorage.getItem('timeZone');
    savedTimeZone && setOfset(savedTimeZone);
  },[])
 

  useEffect(() => {
    let interval = null;
    const savedTimeZone = localStorage.getItem('timeZone');
   console.log(savedTimeZone);
      interval = setInterval(() => {
        const time = new Date();
        let offsetTime = null;

        let h = time.getUTCHours();
        let m = time.getUTCMinutes();
        let s = time.getUTCSeconds();


        console.log(offset);
        if (offset) {
            if (offset.charAt(0) === '+') {
                const os = offset.slice(1);
                console.log(os);
                offsetTime = addHoursToDate(time, parseInt(os));
                h = offsetTime.getUTCHours();
                m = offsetTime.getUTCMinutes();
                s = offsetTime.getUTCSeconds();

            }
            else if (offset.charAt(0) === '-') {

                const os = offset.slice(1);
                console.log(os);
                offsetTime = subtractTimeFromDate(time, parseInt(os));
                h = offsetTime.getUTCHours();
                m = offsetTime.getUTCMinutes();
                s = offsetTime.getUTCSeconds();

            }
        }

            if (!offset && savedTimeZone) {
                if (savedTimeZone.charAt(0) === '+') {
                    const os = savedTimeZone.slice(1);
                    console.log(os);
                    offsetTime = addHoursToDate(time, parseInt(os));
                    h = offsetTime.getUTCHours();
                    m = offsetTime.getUTCMinutes();
                    s = offsetTime.getUTCSeconds();
    
                }
                else if (savedTimeZone.charAt(0) === '-') {
    
                    const os = offset.slice(1);
                    console.log(os);
                    offsetTime = subtractTimeFromDate(time, parseInt(os));
                    h = offsetTime.getUTCHours();
                    m = offsetTime.getUTCMinutes();
                    s = offsetTime.getUTCSeconds();
    
                }

        }

        setHours(addZero(h));
        setMins(addZero(m));
        setSecs(addZero(s));

        console.log(time);
      }, 1000);
    

    return () => clearInterval(interval);
  }, [offset]);

  const addZero = element => {
    if (element === 9 || element < 9) {
      return '0' + element;
    } else {
      return element;
    }
  };

  const changeTimeZoneHandler = event => {
    console.log(event.target.value);
   
    setOfset(event.target.value)
    localStorage.setItem('timeZone', event.target.value);

  };

  const addHoursToDate = (objDate, intHours) => {
    var numberOfMlSeconds = objDate.getTime();
    var addMlSeconds = intHours * 60 * 60 * 1000;
    var newDateObj = new Date(numberOfMlSeconds + addMlSeconds);

    return newDateObj;
  };

  const subtractTimeFromDate = (objDate, intHours) => {
    var numberOfMlSeconds = objDate.getTime();
    var addMlSeconds = intHours * 60 * 60 * 1000;
    var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);

    return newDateObj;
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.hours}>
        <h1>{hours}</h1>
      </div>
      <span>:</span>
      <div className={styles.mins}>
        <h1>{mins}</h1>
      </div>
      <span>:</span>
      <div className={styles.secs}>
        <h1>{secs}</h1>
      </div>
      <div className={styles.actions}>
        <span className={styles.text}>TimeZone: </span>
        <select
          id="timezone"
          name="timeZone"
          className={styles.timeZone}
          onChange={changeTimeZoneHandler}
          value={offset}
        >
          <option value="-12">GMT - 12:00</option>
          <option value="-11">GMT - 11:00</option>
          <option value="-10">GMT - 10:00</option>
          <option value="-9">GMT - 09:00</option>
          <option value="-8">GMT - 08:00</option>
          <option value="-7">GMT - 07:00</option>
          <option value="-6">GMT - 06:00</option>
          <option value="-5">GMT - 05:00</option>
          <option value="-4">GMT - 04:00</option>
          <option value="-3">GMT - 03:00</option>
          <option value="-2">GMT - 02:00</option>
          <option value="-1">GMT - 01:00</option>
          <option value="0">GMT</option>
          <option value="+1">GMT + 01:00</option>
          <option value="+2">GMT + 02:00</option>
          <option value="+3">GMT + 03:00</option>
          <option value="+4">GMT + 04:00</option>
          <option value="+5">GMT + 05:00</option>
          <option value="+6">GMT + 06:00</option>
          <option value="+7">GMT + 07:00</option>
          <option value="+8">GMT + 08:00</option>
          <option value="+9">GMT + 09:00</option>
          <option value="+10">GMT + 10:00</option>
          <option value="+11">GMT + 11:00</option>
          <option value="+12">GMT + 12:00</option>
          <option value="+13">GMT + 13:00</option>
          <option value="+14">GMT + 14:00</option>
        </select>
      </div>
      <div className={styles.title}>EventStreams.co</div>
    </div>
  );
};

export default Clock;
